import React, {createContext, useContext, useEffect, useState} from 'react';
import {AuthContext} from "./AuthContext";

const ZoomContext = createContext();

// Eagerly fetches User on zoom change, since it's possible the user was sitting
// on a custom zoom for a while (which doesn't auto-refresh). If they then
// clicked a further drag-zoom and their access token expired, then they
// wouldn't see any updated data.
const ZoomProvider = ({ children }) => {
  var now = Date.now();
  const [since, setSince] = useState(now-12*3600*1000);
  const [until, setUntil] = useState(now);
  const [label, setLabel] = useState("12 Hours");
  const [refreshInterval, setRefreshInterval] = useState(20000);
  const [lastInterval, setLastInterval] = useState(0);
  const {user, fetchUser} = useContext(AuthContext);

  console.log("Creating ZoomProvider");

  const setCustomTimeRange = (newSince, newUntil) => {
    fetchUser().then(function() {
      setSince(newSince);
      setUntil(newUntil);
      setLabel("Custom");
      clearInterval(lastInterval);
    });
  };

  const selectZoom = (newSince, newUntil, newLabel) => {
    fetchUser().then(function() {
      setSince(newSince);
      setUntil(newUntil);
      setLabel(newLabel);
    });
  };

  const advanceZoomToNow = () => {
    if (label === "Custom") {
      console.error("Shouldn't advance when set to custom?", label, since, until);
      return;
    }

    const newUntil = Date.now();
    const newSince = since + (newUntil - until);
    selectZoom(newSince, newUntil, label);
  };

  useEffect(() => {
    if (!user) {
      clearInterval(lastInterval);
      return;
    }

    if (refreshInterval && refreshInterval > 0) {
      if (label !== "Custom") {
        const interval = setInterval(advanceZoomToNow, refreshInterval);

        // Remember lastInterval in case user does a drag zoom and therefore
        // we want to stop the auto-fresh
        setLastInterval(interval);
        return () => clearInterval(interval);
      }
    }
  }, [user, label]);

  return (
      <ZoomContext.Provider value={{ since, until, label, setCustomTimeRange, selectZoom }}>
        {children}
      </ZoomContext.Provider>
  );
};

export { ZoomContext, ZoomProvider };
