import React, {useContext, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {AuthContext} from "../contexts/AuthContext";

const EmailSignOut = () => {
  const {user, isLoadingUser, logout} = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  };

  if (isLoadingUser) {
    return (
        <div>Loading...</div>
    );
  }
  if (!user) {
    return;
  }

  return (
      <div className="email-signout-row">
        <Button size="small" variant="outlined" onClick={handleLogout}>Logout</Button>
      </div>
  );
}

const EmailSignin = () => {
  const [username, setUsername] = useState('');
  const {user, errMessage, isLoadingUser, login} = useContext(AuthContext);

  const handleLogin = async () => {
    const u = username;
    setUsername("")
    console.log("onclick", user, u, !!login)
    login({"username": u});
  };

  if (isLoadingUser) {
    return (
        <div>Loading...</div>
    );
  }

  if (user) {
    return;
  }

  // TODO clean up the silliness with display flex still on somethings, or in wrong places? Look at dev tools for what it says is being disabled automatically because of other styles.
  const errClass = errMessage ? "email-signin-error" : "email-signin-error-hide";

  return (
      <div className="email-signin">
        <div className={errClass}>{errMessage}</div>
        <div className="email-signin-row">
          <TextField
              size="small"
              label="Login with email address"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{width: '32ch'}}/>
          <Button
              size="small"
              variant="outlined"
              onClick={handleLogin}>Login</Button>
        </div>
        <div className="email-signin-helper">You're logged out.</div>
        <div className="email-signin-helper">Enter your email address above to receive a link to login.</div>
      </div>
  );
}

export {EmailSignin, EmailSignOut};
