import React, {useState} from 'react';

import {GiExpand} from "react-icons/gi";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DeviceChart from "./DeviceChart";
import Zoom from "./Zoom";
import {EmailSignOut} from "./EmailSignin";

const ExpandedDeviceChartDialog = ({client, device, onDragZoom}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <React.Fragment>
        <GiExpand className="device-header-labels-display-name-form" title="Expand" size="18px" onClick={handleClickOpen}/>
        <Dialog
            fullWidth={true}
            maxWidth={false}
            open={open}
            onClose={handleClose}
        >
          <DialogContent>
            <div className="header">
              <Zoom/>
              <EmailSignOut/>
            </div>
            <DeviceChart client={client} device={device} onDragZoom={onDragZoom}
                         style={{'height': 'calc(90vh - 180px)'}} targetPoints={1280} showSignalLabel={true} showExpand={false}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
  );
};

export default ExpandedDeviceChartDialog;

