import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {Bounce, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [errMessage, setErrMessage] = useState(null);
  console.log("Creating AuthProvider");

  const fetchUser = async () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      try {
        console.log("Fetching /api/user");
        const response = await axios.get('/api/user', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (errMessage) {
          console.log("AuthProvider: Setting err message from", errMessage, "to", null);
          setErrMessage(null);
        }
        if (!user || response.data.accessToken !== accessToken) {
          console.log("AuthProvider: Setting user from", user, "to", response.data);
          setUser(response.data);
        }
      } catch (error) {
        if (error.status === 401) {
          console.log("AuthProvider: Access token expired, calling refreshToken() to try refresh");
          await refreshToken(); // Attempt to refresh token
          console.log("AuthProvider: Calling fetchUser() again");
          await fetchUser(); // Retry fetching profile
          return;
        } else {
          // Handle other errors
          console.error('AuthProvider: Non-auth error fetching user:', error);
          setErrMessage(error.message || "Unknown error");
        }
      }
    }

    if (isLoadingUser) {
      console.log("AuthProvider: Setting isLoadingUser false");
      setIsLoadingUser(false);
    }
  };

  useEffect(() => {
    console.log("AuthContext use effect calling refreshToken()");
    refreshToken().then(function() {
      console.log("AuthContext use effect now calling fetchUser()");
      fetchUser();
      console.log("AuthContext use effect returning");
    });
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.post('/api/user/refresh', {});
      console.log("Refresh response successful");

      const { accessToken } = response.data;
      Cookies.set('accessToken', accessToken);
      setErrMessage(null);
    } catch (error) {
      if (error.status === 401) {
        console.error("Refresh token expired, user must login again");
        const accessToken = getAccessToken();
        logout().then(function () {
          if (accessToken) {
            // Only display session expired message if I had an access token to begin with
            setErrMessage(
                "Session expired, please login again by requesting a new email link");
          }
        });
      } else {
        // Handle other errors
        console.error('Non-auth error from refresh endpoint:', error);
        setErrMessage(error.message || "Unknown error");
      }
    }
  };

  const getAccessToken = () => {
    return Cookies.get('accessToken');
  };

  const login = async (credentials) => {
    try {
      console.log("email login invoked with", credentials)
      const response = await axios.post('/api/email', credentials);
      console.log("received /api/email response", response)
      setErrMessage(null);
      toast.info('Email sent containing link to sign in', {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    } catch (error) {
      console.error('Error from /api/email', error);
      setErrMessage(error.message || "Unknown error");
    }
  };

  const logout = async () => {
    console.log("Logging out user");
    try {
      Cookies.remove('accessToken');
      const response = await axios.get('/api/user/logout', {});
      console.log("Logged out successfully", response);
      setUser(null);
    } catch (err) {
      // there is no "expected" way that /logout might return an error, so it
      // must be a resource error
      console.error("Failure logging out", err)
      toast.error('An error occurred logging out: "' + err.message + '"', {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  // ... logout and other auth functions

  return (
      <AuthContext.Provider value={{ user, errMessage, isLoadingUser, getAccessToken, fetchUser, login, logout }}>
        {children}
      </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
