/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.2
// source: proto/govee.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./govee_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.GoveeBackendClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.GoveeBackendPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RegisterDeviceRequest,
 *   !proto.proto.Device>}
 */
const methodDescriptor_GoveeBackend_RegisterDevice = new grpc.web.MethodDescriptor(
  '/proto.GoveeBackend/RegisterDevice',
  grpc.web.MethodType.UNARY,
  proto.proto.RegisterDeviceRequest,
  proto.proto.Device,
  /**
   * @param {!proto.proto.RegisterDeviceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Device.deserializeBinary
);


/**
 * @param {!proto.proto.RegisterDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.Device)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Device>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.GoveeBackendClient.prototype.registerDevice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.GoveeBackend/RegisterDevice',
      request,
      metadata || {},
      methodDescriptor_GoveeBackend_RegisterDevice,
      callback);
};


/**
 * @param {!proto.proto.RegisterDeviceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Device>}
 *     Promise that resolves to the response
 */
proto.proto.GoveeBackendPromiseClient.prototype.registerDevice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.GoveeBackend/RegisterDevice',
      request,
      metadata || {},
      methodDescriptor_GoveeBackend_RegisterDevice);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.GoveeUIClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.GoveeUIPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.TimeRange,
 *   !proto.proto.Device>}
 */
const methodDescriptor_GoveeUI_ListDevices = new grpc.web.MethodDescriptor(
  '/proto.GoveeUI/ListDevices',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.proto.TimeRange,
  proto.proto.Device,
  /**
   * @param {!proto.proto.TimeRange} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Device.deserializeBinary
);


/**
 * @param {!proto.proto.TimeRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Device>}
 *     The XHR Node Readable Stream
 */
proto.proto.GoveeUIClient.prototype.listDevices =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/proto.GoveeUI/ListDevices',
      request,
      metadata || {},
      methodDescriptor_GoveeUI_ListDevices);
};


/**
 * @param {!proto.proto.TimeRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Device>}
 *     The XHR Node Readable Stream
 */
proto.proto.GoveeUIPromiseClient.prototype.listDevices =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/proto.GoveeUI/ListDevices',
      request,
      metadata || {},
      methodDescriptor_GoveeUI_ListDevices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.TimeSeriesRequest,
 *   !proto.proto.Sample>}
 */
const methodDescriptor_GoveeUI_GetSamples = new grpc.web.MethodDescriptor(
  '/proto.GoveeUI/GetSamples',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.proto.TimeSeriesRequest,
  proto.proto.Sample,
  /**
   * @param {!proto.proto.TimeSeriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Sample.deserializeBinary
);


/**
 * @param {!proto.proto.TimeSeriesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Sample>}
 *     The XHR Node Readable Stream
 */
proto.proto.GoveeUIClient.prototype.getSamples =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/proto.GoveeUI/GetSamples',
      request,
      metadata || {},
      methodDescriptor_GoveeUI_GetSamples);
};


/**
 * @param {!proto.proto.TimeSeriesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Sample>}
 *     The XHR Node Readable Stream
 */
proto.proto.GoveeUIPromiseClient.prototype.getSamples =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/proto.GoveeUI/GetSamples',
      request,
      metadata || {},
      methodDescriptor_GoveeUI_GetSamples);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.TimeSeriesRequest,
 *   !proto.proto.TimeSeriesResponse>}
 */
const methodDescriptor_GoveeUI_GetSamplesWithMetadata = new grpc.web.MethodDescriptor(
  '/proto.GoveeUI/GetSamplesWithMetadata',
  grpc.web.MethodType.UNARY,
  proto.proto.TimeSeriesRequest,
  proto.proto.TimeSeriesResponse,
  /**
   * @param {!proto.proto.TimeSeriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.TimeSeriesResponse.deserializeBinary
);


/**
 * @param {!proto.proto.TimeSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.TimeSeriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.TimeSeriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.GoveeUIClient.prototype.getSamplesWithMetadata =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.GoveeUI/GetSamplesWithMetadata',
      request,
      metadata || {},
      methodDescriptor_GoveeUI_GetSamplesWithMetadata,
      callback);
};


/**
 * @param {!proto.proto.TimeSeriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.TimeSeriesResponse>}
 *     Promise that resolves to the response
 */
proto.proto.GoveeUIPromiseClient.prototype.getSamplesWithMetadata =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.GoveeUI/GetSamplesWithMetadata',
      request,
      metadata || {},
      methodDescriptor_GoveeUI_GetSamplesWithMetadata);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DisplayNameRequest,
 *   !proto.proto.DisplayNameResponse>}
 */
const methodDescriptor_GoveeUI_UpdateDeviceDisplayName = new grpc.web.MethodDescriptor(
  '/proto.GoveeUI/UpdateDeviceDisplayName',
  grpc.web.MethodType.UNARY,
  proto.proto.DisplayNameRequest,
  proto.proto.DisplayNameResponse,
  /**
   * @param {!proto.proto.DisplayNameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DisplayNameResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DisplayNameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DisplayNameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DisplayNameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.GoveeUIClient.prototype.updateDeviceDisplayName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.GoveeUI/UpdateDeviceDisplayName',
      request,
      metadata || {},
      methodDescriptor_GoveeUI_UpdateDeviceDisplayName,
      callback);
};


/**
 * @param {!proto.proto.DisplayNameRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DisplayNameResponse>}
 *     Promise that resolves to the response
 */
proto.proto.GoveeUIPromiseClient.prototype.updateDeviceDisplayName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.GoveeUI/UpdateDeviceDisplayName',
      request,
      metadata || {},
      methodDescriptor_GoveeUI_UpdateDeviceDisplayName);
};


module.exports = proto.proto;

