import React, { useState, useEffect, useContext } from 'react';
import {StatusCode} from 'grpc-web';
import { useMediaQuery } from 'react-responsive';
import PulseLoader from "react-spinners/PulseLoader";

import {AuthContext} from "../contexts/AuthContext";
import DeviceChart from "./DeviceChart"
import { TimeRange } from "../goveepb/proto/govee_pb"
import { GoveeUIClient } from "../goveepb/proto/govee_grpc_web_pb"
import {EmailSignin} from "./EmailSignin";
import {ZoomContext} from "../contexts/ZoomContext";

const DeviceList = () => {
  const [devices, setDevices] = useState([]);
  const [isLoadingDevices, setIsLoadingDevices] = useState(true);
  const [errClassName, setErrClassName] = useState("err-message-hide");
  const [errMessage, setErrMessage] = useState(null);
  const { user, isLoadingUser, fetchUser, getAccessToken } = useContext(AuthContext);
  const { since, until, setCustomTimeRange } = useContext(ZoomContext);

  const client = new GoveeUIClient(process.env.REACT_APP_GRPC_URL);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1124px)'
  });

  const fetchDevices = (isLastTry) => {
    console.log("fetching device list");
    if (isLoadingUser) {
      return;
    }

    const accessToken = getAccessToken();
    const metadata = {"Authorization": "Bearer " + accessToken}
    if (!accessToken) {
      setIsLoadingDevices(false);
      return;
    }

    const timeRange = new TimeRange([since, until]);
    console.log("Fetching list of devices with timeRange", new Date(since), new Date(until), "with token", accessToken);
    const stream = client.listDevices(timeRange, metadata);

    const batch = [];
    stream.on('data', (device) => {
      batch.push(device)
    });

    stream.on('end', () => {
      console.log("Received fresh batch of devices:", batch.length)
      setErrClassName("err-message-hide")
      setDevices(batch);
      setIsLoadingDevices(false);
    });

    stream.on('error', (err) => {
      if (err.code === StatusCode.UNAUTHENTICATED) {
        if (!isLastTry) {
          console.error("Received auth error getting batch of devices, attempting to refresh auth and retry", err)
          fetchUser().then(function() {
            console.log("Refresh attempt complete, retrying device fetch")
            fetchDevices(true);
          });
          return;
        }
      }

      console.error("Received error getting batch of devices", err);
      var message = "An error occurred loading the list of Govee devices";
      if (err && err.message) {
        message += ': "' + err.message + '"';
      }
      message += ". Will retry automatically."
      setErrMessage(message);
      setErrClassName("err-message-show");
      setIsLoadingDevices(false);
    });
  }

  useEffect(() => {
    if (isLoadingUser) {
      return;
    }

    console.log("DeviceList: use effect, about to fetch Devices")
    fetchDevices();
  }, [user, isLoadingUser, since, until]);

  const onDragZoom = (left, right) => {
    console.log("onDragZoom, setting custom time range", new Date(left), new Date(right));
    setCustomTimeRange(left, right, "Custom");
  };

  const charts = devices.map((device, index) => {
    return (
        <li className="device-list-entry" key={index}>
          <DeviceChart key={index} client={client} device={device} onDragZoom={onDragZoom} showExpand={true}/>
        </li>
    );
  });

  if (isLoadingUser || isLoadingDevices) {
    return (
        <>
          <div className={errClassName}>{errMessage}</div>
          <div className="device-list">
            <ul>
              <li className="device-list-empty-state">
                <PulseLoader speedMultiplier={0.8} color="#629af0"/>
              </li>
            </ul>
          </div>
        </>
    );
  }

  return (
      <>
        <div className={errClassName}>{errMessage}</div>
        <div className="device-list">
          {!user && <EmailSignin/>}
          <ul>
            {user && charts.length > 0 && charts}
            {user && charts.length === 0 && (
                <li className="device-list-empty-state">No Govee temperature sensors found</li>
            )}
          </ul>
        </div>
      </>

  );
};

export default DeviceList;
