import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import React, {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {ZoomContext} from "../contexts/ZoomContext";

const options = [
  { value: "600000", label: "10 Minutes", disabled: false },
  { value: "3600000", label: "1 Hour", disabled: false },
  { value: "7200000", label: "2 Hours", disabled: false },
  { value: "21600000", label: "6 Hours", disabled: false },
  { value: "43200000", label: "12 Hours", disabled: false },
  { value: "86400000", label: "24 Hours", disabled: false },
  { value: "172800000", label: "2 Days", disabled: false },
  { value: "604800000", label: "7 Days", disabled: false },
  { value: "2592000000", label: "30 Days", disabled: false },
  { value: "5443200000", label: "2 Months", disabled: false },
  { value: "15724800000", label: "6 Months", disabled: false },
  { value: "31449600000", label: "1 Year", disabled: false },
];

const optionsWithCustom = [
  { value: "600000", label: "10 Minutes", disabled: false },
  { value: "3600000", label: "1 Hour", disabled: false },
  { value: "7200000", label: "2 Hours", disabled: false },
  { value: "21600000", label: "6 Hours", disabled: false },
  { value: "43200000", label: "12 Hours", disabled: false },
  { value: "86400000", label: "24 Hours", disabled: false },
  { value: "172800000", label: "2 Days", disabled: false },
  { value: "604800000", label: "7 Days", disabled: false },
  { value: "2592000000", label: "30 Days", disabled: false },
  { value: "5443200000", label: "2 Months", disabled: false },
  { value: "15724800000", label: "6 Months", disabled: false },
  { value: "31449600000", label: "1 Year", disabled: false },
  { value: "0", label: "Custom", disabled: false },
];

// const customOption = { value: "-1", label: "Custom", disabled: false };
const defaultOption = options[4];

const findByLabel = (labelValue) => {
  // Should always be found
  return optionsWithCustom.find((o) => o.label === labelValue);
}

export default function Zoom() {
  const { user, isLoadingUser } = useContext(AuthContext);
  const { label, selectZoom } = useContext(ZoomContext);

  const selectedOption = findByLabel(label);
  const availableOptions = (label === "Custom")
    ? optionsWithCustom
    : options;

  function handleChange(s) {
    const o = availableOptions.find((val) => val.value === s.target.value)

    const newUntil = Date.now();
    const newSince = newUntil-parseInt(o.value);
    const newLabel = o.label;
    selectZoom(newSince, newUntil, newLabel);
  }

  if (!user || isLoadingUser) {
    return;
  }

  const autoRefreshCopy = label === "Custom"
    ? "Charts will not auto-refresh"
    : "Charts auto-refresh every 20 secs"
  
  return (
      <div className="zoom">
        <div className="zoom-dropdown">
          <TextField
              id="chart-zoom"
              select
              sx={{ width: '18ch' }}
              onChange={handleChange}
              size="small"
              label="Chart Zoom"
              value={selectedOption.value}
              defaultValue={defaultOption.value}
              helperText=""
          >
            {availableOptions.map((option) => (
                <MenuItem key={option.value} name={option.label} value={option.value} disabled={option.disabled} selected={selectedOption.value === option.value}>
                  {option.label}
                </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="zoom-label-refresh">{autoRefreshCopy}</div>
      </div>
  );
}
