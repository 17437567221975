import React from 'react';
import { useMediaQuery } from 'react-responsive';

const yellowPercent = 0.6;
const redPercent = 0.3;

const BatteryCell = ({health}) => {
  return (
      <div className={"battery-cell-" + health}/>
  );
}

const BatteryMeter = ({battery, numLevels, onLegendClick}) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1124px)'
  });

  const isPortraitMobile = useMediaQuery({
    query: '(max-width: 700px)'
  });

  if (isPortraitMobile) {
    numLevels = numLevels - 1;
  }

  const table = createTable(numLevels);
  const emptyIndex = numLevels - Math.ceil(battery / 100 * numLevels);

  const heading = isDesktopOrLaptop
      ? "Battery: " + battery + "%"
      : (isPortraitMobile ? battery + "%" : "Batt: " + battery + "%");

  const cellsWidth = isPortraitMobile ? '28px' : '40px';

  const batteryCells = table.map((color, index) => {
    if (index < emptyIndex) {
      return (<BatteryCell key={index} health="empty"/>);
    } else {
      return (<BatteryCell key={index} health={color}/>);
    }
  });

  return (
      <div className="battery-meter-container">
        <div className="battery-meter">
          <a onClick={onLegendClick} name="battery-legend" className="battery-meter-header">{heading}</a>
          <div className="battery-meter-cells" style={{width: cellsWidth}}>
            {batteryCells}
          </div>
        </div>
      </div>
  )
};

const createTable = (numLevels) => {
  var table = Array(numLevels).fill("red");
  for (var i = 0; i < numLevels; i++) {
    let percent = (numLevels - i) / numLevels;
    if (percent >= yellowPercent) {
      table[i] = "green";
    } else if (percent >= redPercent) {
      table[i] = "yellow"
    } else {
      table[i] = "red"
    }
  }
  return table;
}


export default BatteryMeter;
