
import React from 'react';
import './App.css';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DeviceList from "./components/DeviceList"
import Zoom from "./components/Zoom";
import {AuthProvider} from "./contexts/AuthContext";
import {ZoomProvider} from "./contexts/ZoomContext";
import {EmailSignOut} from "./components/EmailSignin";

function App() {
  return (
      <>
        <AuthProvider>
          <h2 className="govee-title">Govee Temperature and Humidity</h2>
          <ZoomProvider>
            <div className="header">
              <Zoom/>
              <EmailSignOut/>
            </div>
            <DeviceList/>
          </ZoomProvider>
        </AuthProvider>
        <ToastContainer
            position="bottom-left"
            autoClose={4000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Bounce}
        />
      </>
  );
}

export default App;
